<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="text-center text-[--surface-600] text-4xl font-bold">
  {{ 'PLAN.TITLE' | translate }}
</div>
<div class="text-left md:text-center text-[--surface-600] text-2xl break-words px-5 sm:px-0">
  {{ 'PLAN.SUB_TITLE' | translate }}
</div>
<div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mx-5 mt-5">
  <p-card
    styleClass="p-5 h-full col-span-2 flex justify-center"
    *ngFor="let content of cardsContent; let i = index; trackBy: identify"
    [ngClass]="{ 'xl:col-span-1 md:col-span-2 md:flex md:justify-center': i === 2 }">
    <div class="flex flex-col justify-between h-full">
      <div class="h-5/6">
        <p class="text-[--surface-600] text-3xl font-bold text-center">
          {{ content.title | translate }}
        </p>
        <p class="text-[--surface-600] text-xl text-center">{{ content.subTitle | translate }}</p>
        <div class="text-center py-3 px-10">
          <div class="flex my-2" *ngFor="let item of content.numOptions | fill; trackBy: counter">
            <i class="pi pi-check text-base text-[--primary-color] self-center mr-2"></i>
            <div class="text-left text-base">
              {{ optionName(content.name, item) | translate }}
            </div>
          </div>
        </div>
      </div>

      <p-button
        [id]="content.id"
        label="{{ content.buttonText | translate }}"
        class="text-2xl text-center whitespace-nowrap"></p-button>
    </div>
  </p-card>
</div>
