<p-toolbar class="layout-footer" styleClass="border-0 rounded-none">
  <ng-template pTemplate="start"></ng-template>
  <ng-template pTemplate="center">
    <div class="flex flex-wrap align-items-center gap-3 text-center">
      <a
        href="#"
        class="p-link no-underline text-[--surface-600] inline-flex justify-content-center align-items-center text-red h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200">
        {{ 'FOOTER.TERMS' | translate }}
      </a>
      <a
        href="#"
        class="p-link no-underline text-[--surface-600] inline-flex justify-content-center align-items-center h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200">
        {{ 'FOOTER.PRIVACY' | translate }}
      </a>
      <a
        href="#"
        class="p-link no-underline text-[--surface-600] inline-flex justify-content-center align-items-center h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200">
        {{ 'FOOTER.COOKIES' | translate }}
      </a>
    </div>
  </ng-template>
  <ng-template pTemplate="end"></ng-template>
</p-toolbar>
