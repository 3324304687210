<div class="text-center text-[--primary-color-text] text-4xl font-bold">
  {{ 'JOURNEY.TITLE' | translate }}
</div>
<div
  class="text-center md:text-center text-[--primary-color-text] text-2xl break-words px-5 sm:px-0">
  {{ 'JOURNEY.SUB_TITLE' | translate }}
</div>
<div class="h-80 relative aspect-square mx-auto">
  <img alt="Card" ngSrc="{{ baseImageUrl }}/chosee-us5.png" fill priority />
</div>
<p-button
  styleClass="w-40 h-50 bg-[--surface-a] text-[--text-color]"
  class="text-2xl text-left"
  label="{{ 'JOURNEY.BUTTON' | translate }}" />
